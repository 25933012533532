import React from "react";

// Image files
import webp from "../../images/pipeline.webp";
import png1 from "../../images/pipeline.png";
import jpg1 from "../../images/pipeline.jpg";

// SCSS
import { pipeline, pipelinePicture } from "../../style/style.module.scss";

/*
 * https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images
 */
const Pipeline = (): JSX.Element => (
  <picture className={pipelinePicture}>
    <source type="image/webp" srcSet={webp} />
    <source type="image/jpeg" srcSet={jpg1} />
    <img src={png1} className={pipeline} alt="Pipeline Chart" />
  </picture>
);

export default Pipeline;
