import React from "react";

// Components
import SEO from "../components/seo";
import Layout from "../components/layout";

// Pipeline Chart
import Pipeline from "../components/pictures/pipeline";

// SCSS
import { gutter } from "../style/style.module.scss";

// ReCaptcha
import { RunReCaptcha } from "../services/recaptcha";

const ResearchPage = (): JSX.Element => {
  // Render
  return (
    <Layout>
      <SEO title="Pipeline: Research and Development" />
      <RunReCaptcha action="pipeline" />
      <div className={gutter}>
        <h1>Research & Development</h1>

        <h2>Pipeline</h2>
        <p>
          NanoPharmaceuticals’ pipeline includes molecularly targeted
          macromolecules that bind with high affinity to thyrointegrin αvβ3
          receptors that are over-expressed in different types of cancers and
          their associated microenvironment, including tumor endothelial cells,
          tumor macrophages and other activated tumor inflammatory and immune
          cells. NanoPharmaceuticals’ technology platforms are aimed at
          addressing market opportunities for which current therapies are not
          optimally effective or safe.
        </p>
        <p>
          <strong>fb-PMT:</strong> This is NanoPharmaceuticals’ first clinical
          candidate aimed at the treatment of different solid tumors
          (Glioblastoma and Pancreatic Cancers) and hematological malignancies
          (AML). A Phase 1 clinical trial for fb-PMT in patients with GBM is
          ongoing at Yale Cancer Center, which will be followed by Phase II
          trials for patients with glioblastoma, pancreatic cancer and AML.
        </p>
        <p>
          <strong>BG-P-TAT:</strong> This is NanoPharmaceuticals’ advanced
          preclinical candidate for the treatment of neuroblastoma and other
          neuroendocrine cancers. BG-P-TAT is a novel agent with dual targeting
          capabilities for the thyrointegrin αvβ3 receptors and the
          norepinephrine transporter (NET) where both molecular targets are
          differentially expressed in neuroendocrine cancers.
        </p>

        <Pipeline />
      </div>
    </Layout>
  );
};

export default ResearchPage;
